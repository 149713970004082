import yall from 'yall-js';
import { loadImages } from '../misc/images';

document.addEventListener("DOMContentLoaded", () => {
  yall({
    events: {
      load: function (event) {
        if (!event.target.classList.contains("lazy") && event.target.nodeName == "IMG") {
          event.target.classList.remove("lazy-placeholder");
          if (event.target.classList.contains("js-force-job-img") && event.target.nodeName == "IMG") {
            loadImages('.js-job-img');
          }
        }
      }
    }
  });
})
;
